// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 //url: 'http://localhost:1337/api',
  // API_BASE_URL:  'http://localhost:44349/api/'
  API_BASE_URL: 'https://api.efacto.app/LozicsApiNodeTest/api/',
  //  API_BASE_URL: 'https://lozicsmobileapitest.lozics.in',

    //  API_BASE_URL: 'https://lozicsnxtapp.lozics.in/api/'
};
